<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="()=>setLang(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { setLang } from '@/auth/utils'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  computed: {
    locales() {
      return [
        {
          locale: 'en',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/en.png'),
          name: this.$t('English'),
        },
        {
          locale: 'fa',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/fa.png'),
          name: this.$t('Persian'),
        },
        // {
        //   locale: 'cs',
        //   // eslint-disable-next-line global-require
        //   img: require('@/assets/images/flags/cs.png'),
        //   name: this.$t('Czechia'),
        // },
        // {
        //   locale: 'ar',
        //   // eslint-disable-next-line global-require
        //   img: require('@/assets/images/flags/AR.png'),
        //   name: this.$t('Arabic'),
        // },
        // {
        //   locale: 'cn',
        //   // eslint-disable-next-line global-require
        //   img: require('@/assets/images/flags/CN.png'),
        //   name: this.$t('China'),
        // },
        // {
        //   locale: 'ru',
        //   // eslint-disable-next-line global-require
        //   img: require('@/assets/images/flags/RU.png'),
        //   name: this.$t('Russia'),
        // },
      ]
    },
    currentLocale() {
      let language = this.$i18n.locale
      if (!this.locales.find(l => l.locale === this.$i18n.locale)) {
        language = 'en'
      }
      let dir = 'ltr'
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$i18n.isRTL = false
      if (language === 'fa' || language === 'ar') {
        dir = 'rtl'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$i18n.isRTL = true
      }
      document.documentElement.setAttribute(
        'dir',
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        (dir),
      )
      localStorage.setItem('lang', language)
      localize(this.$i18n.locale)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties,vue/no-async-in-computed-properties
      this.$router
        .push({
          params: { lang: language },
        })
        .catch(() => {})

      return this.locales.find(l => l.locale === language)
    },
  },

  methods: {
    setLang(lang) {
      setLang(lang)
    },
  },
}
</script>

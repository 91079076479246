export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'house-door',
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'people',
  },
  // {
  //   title: 'John Doe',
  //   route: { name: 'apps-users-view', params: { id: 21 } },
  //   icon: 'UserIcon',
  // },
  {
    title: 'Documents',
    route: 'document',
    icon: 'card-checklist',
  },
  {
    title: 'exchange',
    icon: 'arrow-left-right',
    href: `${process.env.VUE_APP_EXCHANGE_ADDRESS}/pendingOrder`,
  },
  {
    title: 'WalletList',
    route: 'crypto-wallets',
    icon: 'wallet2',
  },
  // {
  //   title: 'Crypto',
  //   route: '',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'exchange',
  //       href: process.env.VUE_APP_EXCHANGE_ADDRESS,
  //       target: '_blank',
  //     },
  //     {
  //       title: 'Wallet',
  //       route: '',
  //       children: [
  //         {
  //           title: 'create_wallet',
  //           route: 'create_new_wallet',
  //           icon: 'ArrowLeftIcon',
  //         },
  //         {
  //           title: 'WalletList',
  //           route: 'crypto-wallets',
  //           icon: 'ArrowLeftIcon',
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    title: 'deposit',
    route: 'deposit',
    icon: 'cart-plus',
  },
  {
    title: 'withdrawal',
    route: 'withdraw',
    icon: 'cart-dash',
  },

  // {
  //   title: 'Economic Calendar',
  //   route: 'economic-calendar',
  //   icon: 'FileIcon',
  // },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'chat-left-text',
  },
  // {
  //   title: 'Ib-pannel',
  //   route: 'ib-pannel',
  //   icon: 'BookOpenIcon',
  // },
  // {
  //   title: 'Metatrader',
  //   route: 'metatrader',
  //   icon: 'MapIcon',
  // },
  {
    title: 'logout',
    route: 'logout',
    icon: 'power',
  },
]
